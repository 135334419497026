function decline_request(request_id){
    $.post(base_url + 'requests/decline_request/'+request_id,function(response){
        console.log("declined");
        reload_page();
    });
}

function accept_request(request_id){
    $.post(base_url + 'requests/accept_request/'+request_id,function(response){
        console.log("accepted");
        reload_page();
    });
}

function enter_result_request(request_id){
    $()
}

function reload_page(){
    $('#content_wrapper').load(window.location.href + ' #content_wrapper',function(){});
}